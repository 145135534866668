<template>
    <div>
        <svg width="120%" height="120%" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.6192 7.44303C25.6192 11.0767 20.0467 14.0205 13.1732 14.0205C6.29782 14.0205 0.725235 11.0767 0.725235 7.44303C0.725235 3.81114 6.29782 0.865555 13.1732 0.865555C20.0467 0.865555 25.6192 3.81114 25.6192 7.44303Z"
                  fill="#E4A600"/>
            <path d="M25.6095 7.63991C25.4122 11.1796 19.9217 14.017 13.1732 14.017C6.42478 14.017 0.934211 11.1796 0.734981 7.63991C0.731074 7.70552 0.725235 7.77114 0.725235 7.83853C0.725235 11.4704 6.29782 14.4142 13.1732 14.4142C20.0467 14.4142 25.6192 11.4704 25.6192 7.83853C25.6192 7.77114 25.6153 7.70552 25.6095 7.63991Z"
                  fill="#996F00"/>
            <path d="M13.1732 0.865536C19.9685 0.865536 25.4884 3.74373 25.6134 7.3171C25.6153 7.27454 25.6192 7.23376 25.6192 7.19298C25.6192 3.56109 20.0467 0.617264 13.1732 0.617264C6.29782 0.617264 0.725235 3.56109 0.725235 7.19298C0.725235 7.23376 0.729123 7.27454 0.731077 7.3171C0.856084 3.74373 6.37791 0.865536 13.1732 0.865536Z"
                  fill="#FFD054"/>
            <path d="M13.1732 2.47578C10.1125 2.47578 7.62209 4.73684 7.62209 7.51573C7.62209 10.2946 10.1125 12.5557 13.1732 12.5557C16.2339 12.5557 18.7223 10.2946 18.7223 7.51573C18.7223 4.73684 16.2339 2.47578 13.1732 2.47578ZM13.1732 3.03616C15.8921 3.03616 18.1051 5.04541 18.1051 7.51573C18.1051 9.98427 15.8921 11.9935 13.1732 11.9935C10.4523 11.9935 8.23933 9.98427 8.23933 7.51573C8.23933 5.04541 10.4523 3.03616 13.1732 3.03616Z"
                  fill="#1D1D1B"/>
            <path d="M10.4055 5.95518V9.09938L13.6361 7.52816L10.4055 5.95518Z" fill="#1D1D1B"/>
            <path d="M15.3374 9.19158H14.8979V5.8647H15.3374V9.19158Z" fill="#1D1D1B"/>
            <path d="M16.021 9.19158H15.5816V5.8647H16.021V9.19158Z" fill="#1D1D1B"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'Prime',
    }
</script>

<style scoped>

</style>