<template>
    <div>
        <svg width="120%" height="120%" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5232 7.44303C25.5232 11.0767 19.9506 14.0205 13.0752 14.0205C6.20175 14.0205 0.627213 11.0767 0.627213 7.44303C0.627213 3.81114 6.20175 0.865555 13.0752 0.865555C19.9506 0.865555 25.5232 3.81114 25.5232 7.44303Z"
                  fill="#900F0D"/>
            <path d="M25.5134 7.63991C25.3142 11.1796 19.8236 14.017 13.0752 14.017C6.32676 14.017 0.836218 11.1796 0.636988 7.63991C0.633082 7.70552 0.627213 7.77114 0.627213 7.83853C0.627213 11.4704 6.20175 14.4142 13.0752 14.4142C19.9506 14.4142 25.5232 11.4704 25.5232 7.83853C25.5232 7.77114 25.5173 7.70552 25.5134 7.63991Z"
                  fill="#8A0000"/>
            <path d="M13.0752 0.865536C19.8705 0.865536 25.3903 3.74373 25.5173 7.3171C25.5192 7.27454 25.5232 7.23376 25.5232 7.19298C25.5232 3.56109 19.9506 0.617264 13.0752 0.617264C6.20173 0.617264 0.629158 3.56109 0.629158 7.19298C0.629158 7.23376 0.633046 7.27454 0.635 7.3171C0.760007 3.74373 6.27986 0.865536 13.0752 0.865536Z"
                  fill="#C45858"/>
            <path d="M9.81526 10.8727L13.0752 7.91296L16.3352 10.8727C15.466 11.5697 14.3253 11.9935 13.0752 11.9935C11.8271 11.9935 10.6845 11.5697 9.81526 10.8727ZM9.37774 4.55597L12.6377 7.51573L9.37774 10.4755C8.61012 9.68636 8.14329 8.65069 8.14329 7.51573C8.14329 6.38253 8.61012 5.34512 9.37774 4.55597ZM16.3371 4.15873L13.0752 7.12028L9.81526 4.15873C10.6845 3.46179 11.8271 3.03795 13.0752 3.03795C14.3253 3.03795 15.466 3.46179 16.3371 4.15873ZM13.5127 7.51573L16.7727 4.55597C17.5403 5.34512 18.0091 6.38253 18.0091 7.51573C18.0091 8.65069 17.5403 9.68637 16.7727 10.4773L13.5127 7.51573ZM13.0752 2.47578C10.0145 2.47578 7.52411 4.73684 7.52411 7.51573C7.52411 10.2946 10.0145 12.5557 13.0752 12.5557C16.1359 12.5557 18.6263 10.2946 18.6263 7.51573C18.6263 4.73684 16.1359 2.47578 13.0752 2.47578Z"
                  fill="#1D1D1B"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'RedButton',
    }
</script>

<style scoped>

</style>