<template>
    <div class="injector-enlarged-panel-wrapper">
        <div v-for="(item, index) in items" class="img-text-row">
            <component :is="item.icon"  class="svg-icon-component"></component>
            <span style="white-space: pre-line">{{item.description}}</span>
        </div>
    </div>
</template>

<script>
    import RedButton from "@/components/injectors/EnlargedPanel/icons/RedButton"
    import YellowButton from "@/components/injectors/EnlargedPanel/icons/YellowButton"

    export default {
        name: "ControlButtons",
        data() {
            return {
                items: [
                    {
                        icon: RedButton,
                        description: 'Прерывает введение и деактивирует систему.'
                    },
                    {
                        icon: YellowButton,
                        description: 'Инициирует введение. Приостанавливает введение максимум на 20 минут.'
                    },
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/components/injector-enlarged-panel-wrappers.scss";


</style>